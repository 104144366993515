'use strict';

const { CLASSES } = require('./../utils/globals');
const SELECTORS = {
    body: 'body',
    videoPlayBtn: '.js-video-play-btn',
    videoContainer: '.js-video-container',
    slickSlider: '.js-slick-slider',
    videoIframe: '.js-iframe',
    closeVideoBtn: '.js-close-video-btn',
};

/**
 * Open Vimeo/YouTube video pop-up and trigger auto play
 */
function openVideoPopup(e) {
    e.preventDefault();

    let $button = $(this);

    $button.prev(SELECTORS.videoContainer).removeClass(CLASSES.hide).addClass(CLASSES.open);
    $button.addClass(CLASSES.hide);
    $button.parents(SELECTORS.slickSlider).addClass(CLASSES.open);
    $button.prev(SELECTORS.videoContainer).find(SELECTORS.videoIframe).get(0).src = $button.data('url');
    $button.prev(SELECTORS.videoContainer).find(SELECTORS.closeVideoBtn).removeClass(CLASSES.hide);
}

/**
 * Close Vimeo/YouTube video pop-up
 */
function closeVideoPopup(e) {
    e.preventDefault();

    let $button = $(this);

    $button.addClass(CLASSES.hide);
    $button.parents(SELECTORS.slickSlider).find(SELECTORS.videoContainer).removeClass(CLASSES.open);
    $button.parents(SELECTORS.slickSlider).removeClass(CLASSES.open);
    $button.parents(SELECTORS.slickSlider).find(SELECTORS.videoIframe).get(0).src = '';
    $button.parents(SELECTORS.slickSlider).find(SELECTORS.videoPlayBtn).removeClass(CLASSES.hide);
}


/**
 * Init all module related events here
 * @returns {void}
 */
const initEvents = () => {
    $(SELECTORS.videoPlayBtn).on('click', openVideoPopup);
    $(SELECTORS.body).on('click', SELECTORS.videoContainer, SELECTORS.closeVideoBtn, closeVideoPopup);
}

module.exports = {
    initEvents: initEvents(),
}
